<template>
  <div class="card">
    <DataTable 
      :value="topics" 
      :loading="loading" 
      responsiveLayout="scroll" 
      showGridlines 
      :paginator="true"
      :rows="rowsPagValue" 
      :rowsPerPageOptions="[10, 20, 50]" 
      :rowHover="true" 
      :first="firstPagValue" 
      removableSort
      dataKey="id"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
      v-model:filters="filters"
      :sort-field="sortFieldValue" 
      :sort-order="sortOrderValue" 
      @sort="onSort($event)" 
      @page="onSort($event)"
    >
      <template #header>
        <div style="display: flex; align-items: center;">
          <h5 style="margin-bottom: 0;">Topic selection</h5>
          <Button 
            class="p-button-raised p-button-rounded my-3 p-button-help mx-3" 
            icon="pi pi-question-circle" 
            @click="goToHeaderPage('topicPage-header')" >
          </Button>
        </div>
        <div class="table-header row gy-3 d-flex flex-row justify-content-between">
          <div class="col-sm-12 col-md-4 d-flex flex-row justify-content-md-start">
          </div>

          <div class="col-sm-12 col-md-8">
            <div class="d-flex flex-row justify-content-md-end">
              <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined me-4"
                @click="clearFilter()" />
              <span class="p-input-icon-left" style="position: relative;">
                <i class="pi pi-search" style="position: absolute; left: 5%; top:50%" />
                <InputText 
                  v-model="filters['global'].value" 
                  placeholder="Keyword Search" 
                  class="keywordSearch" />
              </span>
            </div>
          </div>
        </div>

      </template>
      <Column :field="'name'" :header="'Name'" :key="'title'" :sortable="true">
        <template #body="slotProps">
          <div @click="onRowSelect(slotProps)" :style="{ 'width': '100%', 'height': '100%', 'cursor': 'pointer' }">
            {{ slotProps.data.name }}
          </div>
        </template>
      </Column>

      <Column :header="'Document count'" :key="'title'" bodyStyle="text-align: right">
        <template #body="slotProps">
          <div>
            {{ slotProps.data.totalDocuments }}
          </div>
        </template>
      </Column>

      <Column :header="'Programme count'" :key="'title'" bodyStyle="text-align: right">
        <template #body="slotProps">
          <div>
            {{ slotProps.data.totalPrograms }}
          </div>
        </template>
      </Column>

      <Column :header="'Last Edit Date'" :key="'title'" bodyStyle="text-align: center">
        <template #body="slotProps">
          <div>
            {{ formatDate(slotProps.data.dateUpdated) }}
          </div>
        </template>
      </Column>

      <template #paginatorstart>
        <Button type="button" icon="pi pi-refresh" class="p-button-text" @click="getTopics()" />
      </template>
    </DataTable>
  </div>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import { useToast } from 'vue-toastification';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import formatToGlobalDate from '../../../../../../shared/helpers/date.helper';
import redirectHelperPage from '../../../../../../shared/helpers/redirectToHelperPage';
const toast = useToast();

export default {
  name: 'TopicsList',
  components: { DataTable, Column, Button, InputText },
  data() {
    return {
      filters: null,
      loading: true,
      selectedTopicId: null,
      // sortvalues 
      sortFieldValue: null,
      sortOrderValue: null,
      //paginationCountValues
      firstPagValue: null,
      rowsPagValue: 50,
    };
  },
  created() {
    this.initFilters();
  },
  async mounted() {
    await this.getTopics();
    this.setMarketDataTopicSort();
    //clean programsColumn Vuex State, for prevent Topic Segmentations issues
    this.cleanProgramsColumn();
  },

  computed: {
    topics() {
      return this.$store.state.topic.topics;
    },
    selectedTopic() {
      return this.$store.state.topic.selectedTopic;
    },
    marketDataTopicSort() {
      return this.$store.getters.obtainMarketDataTopicSort;
    }
  },

  methods: {
    goToHeaderPage(section){
      redirectHelperPage(section);
    },

    async getTopics() {
      try {
        this.loading = true;
        await this.$store.dispatch('obtainFilteredTopics', { totalDocuments: true, totalPrograms: true });
        // await this.obtainTopics();
        // toast.success('Topics obtained successfully.');
        this.loading = false;
      } catch (error) {
        toast.error(error.message);
        this.loading = false;
      }
    },
    setMarketDataTopicSort() {
      try {

        //sort
        this.sortFieldValue = this.marketDataTopicSort.sortField;
        this.sortOrderValue = this.marketDataTopicSort.sortOrder;

        //pag
        if (this.marketDataTopicSort.firstPagValue === null) {
          this.firstPagValue = 0;
        }
        else {
          this.firstPagValue = this.marketDataTopicSort.firstPagValue;
        }
        if (this.marketDataTopicSort.rowsPagValue === null) {
          this.rowsPagValue = 50;
        }
        else {
          this.rowsPagValue = this.marketDataTopicSort.rowsPagValue
        }
      } catch (error) {
        console.log(error);
      }
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ]
        }
      }
    },
    formatDate(date) {
      return formatToGlobalDate(date);
    },
    clearFilter() {
      this.initFilters();
    },
    async onRowSelect(event) {
      this.selectedTopicId = event.data.topicId;
      const topicName = event.data.name;
      await this.$store.dispatch('updateSelectedTopic', this.selectedTopicId);
      toast.success(`You have changed the topic to: ${topicName}`);
      this.$router.push('/admin/topics/documents')
    },

    //sortFunctions
    onSort(e) {
      const marketDataTopicSort = {
        sortField: e.sortField,
        sortOrder: e.sortOrder,
        firstPagValue: e.first,
        rowsPagValue: e.rows
      }
      this.$store.dispatch('updateMarketDataTopicSort', marketDataTopicSort)
    },
    //ProgramsColumn
    cleanProgramsColumn() {
      this.$store.dispatch('cleanProgramsColumn');
    },
  },
  beforeUnmount() {
    this.$store.dispatch('cleanTopics');
  }

};
</script>
<style scoped>
.keywordSearch {
  /* padding: 0 0 0 2.5rem !important; */
  padding: 0.2rem 0 0.2rem 2.5rem  !important;
}
</style>